import React from 'react'
import Helmet from 'react-helmet'
import Img from "gatsby-image";

import { Link, graphql } from 'gatsby'
import get from 'lodash/get'

import styled from 'styled-components'
import Layout from '../components/Layout'

class Blog extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(
      this,
      'props.data.site.siteMetadata.description'
    )
    const siteKeywords = get(
      this,
      'props.data.site.siteMetadata.siteKeywords'
    )
    const posts = get(this, 'props.data.allMarkdownRemark.edges')

    const siteUrl = get(
      this,
      'props.data.site.siteMetadata.siteUrl'
    )
    const image = siteUrl + get(this, 'props.data.blogImage.sizes.src');

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[
            //html tags
            { name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords},
            { itemprop: 'name', content: siteTitle },
            { itemprop: 'description', content: siteDescription},
            { itemprop: 'image', content: image},
            //facebook tags
            {property: 'og:title', content: siteTitle},
            {property: 'og:type', content: 'website'},
            {property: 'og:image', content: image},
            {property: 'og:url', content: siteUrl},
            {property: 'og:description', content: siteDescription},
            //twitter tags
            {property: 'twitter:card', content: 'twitter:card'},
            {property: 'twitter:title', content: siteTitle},
            {property: 'twitter:creator', content: '@likeOMGitsFEDAY'},
            {property: 'twitter:image', content: image},
            {property: 'twitter:url', content: siteUrl},
            {property: 'twitter:description', content: siteDescription}
          ]}
          title={siteTitle}
        />
        <BlogContainer>
          <div className="container">
            <div className="row blog-article">
              <div className="col"><h1>On The Blog</h1></div>
            </div>
            {posts.map(({ node }) => {
              const title = get(node, 'frontmatter.title') || node.fields.slug;
              const description = node.frontmatter.description || node.excerpt;
              if (!node.frontmatter.thumbnail) {
                //console.log(node.fields.slug); // or whatever, however you identify a post
              }
              if(node.frontmatter.layout != 'draft') {
                return (
                  <div className="row blog-article" key={node.fields.slug}>
                    <div className="col">
                      <Img
                        title={title}
                        alt={title}
                        sizes={node.frontmatter.thumbnail.image.sizes}
                      />
                    </div>
                    <div className="col-9">
                      <h2>
                        <Link style={{ boxShadow: 'none' }} to={node.fields.slug}>
                            {title}
                        </Link>
                        <p>{node.frontmatter.date}</p>
                        <p dangerouslySetInnerHTML={{ __html: description }} />
                      </h2>
                    </div>

                  </div>
                )
              }

            })}
          </div>
        </BlogContainer>
      </Layout>
    )
  }
}

const BlogContainer = styled.div`
  background-color:#fff;
  color: #696969;

  .blog-article {
    border-bottom: solid 1px #CCC;
    padding: 40px 0px 20px 0px;
  }

  @media(min-width: 1200px){
    .container {
      max-width: 1020px;
    }
  }
  h1, h2 {
    text-transform: none;
  }

  p {
    text-transform:none;
    font-weight: normal;
  }
`

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        siteKeywords
        siteUrl
      }
    }
    blogImage: imageSharp(original: {src: { regex: "/default-blog/" }} ) {
      sizes(maxWidth: 1240 ) {
        ...GatsbyImageSharpSizes
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            layout
            description
            thumbnail{
              image:childImageSharp {
                sizes(maxWidth: 2000 ) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
  }
`
